import { render, staticRenderFns } from "./MovePredictionToSaving.vue?vue&type=template&id=852ff6bc&scoped=true&"
import script from "./MovePredictionToSaving.vue?vue&type=script&lang=js&"
export * from "./MovePredictionToSaving.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "852ff6bc",
  null
  
)

export default component.exports