<template>
  <div class="move-to-saving-summary">
    <popup :title="title"
           :close-action="close">
      <template v-slot:content>
        <change-category-path :old-category="fromCategory" :new-category="toCategory" />
        <div v-if="!hasActual">
          <transaction-amount :amount="predictedTransaction.predictedAmount" class="details"/>
          <prediction-details :predicted-transaction="predictedTransaction" class="details" />
        </div>
        <transaction-details v-else :transaction="predictedTransaction.actual" />
        <riseup-button title="אישור" size="slim" :action="onSave" class="cta"/>
      </template>
    </popup>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import SegmentTracker from '@/mixins/SegmentTracker';
import PredictionDetails from '../PredictionDetails';
import ChangeCategoryPath from '../ChangeCategoryPath.vue';
import TransactionAmount from '../TransactionAmount';
import TransactionDetails from '../TransactionDetails';

export default {
  name: 'MovePredictionToSavingSummary',
  components: {
    PredictionDetails,
    Popup: BaseUI.Popup,
    ChangeCategoryPath,
    TransactionAmount,
    RiseupButton: BaseUI.RiseupButton,
    TransactionDetails,
  },
  props: {
    predictedTransaction: { type: Object, required: true },
    fromCategory: { type: Object, required: true },
    toCategory: { type: Object, required: true },
    title: { type: String, default: 'ההוצאה תעבור' },
  },
  mixins: [SegmentTracker],
  created() {
    this.initTracker('MovePredictionToSavingSummary_',
      {
        predictedTransaction: this.predictedTransaction,
        fromCategory: { cashflowCategory: this.fromCategory.cashflowCategory, categoryName: this.fromCategory.categoryName },
        toCategory: { cashflowCategory: this.toCategory.cashflowCategory, categoryName: this.toCategory.categoryName },
      });
    this.track('Entered');
  },
  computed: {
    hasActual() {
      return !!this.predictedTransaction.actual;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    close() {
      this.track('CloseClicked');
      this.closeModal();
    },
    async onSave(event) {
      this.track('SaveClicked');
      this.$emit('save', event);
      this.closeModal();
    },
  },
};
</script>

<style scoped lang="scss">

@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '@/scss/category-color-mixins';

.move-to-saving-summary {

  .change-category-path {
    margin-bottom: 40px;
  }

  .details {
    margin-bottom: 12px;
  }

  .cta {
    margin-top: 40px;
  };
}

</style>
