<template>
  <toaster @close-toaster="closeSummaryToaster">
    <template v-slot:text>
      <span><span class="bold">צפי ה{{ incomeOrExpense }}</span> נמחק מהתזרים</span>
    </template>
  </toaster>
</template>

<script>
import EventBus from '@/event-bus/event-bus';
import Toaster from '../../../../../../base-components/Toaster';

export default {
  name: 'PredictionDeletedToaster',
  components: {
    Toaster,
  },
  props: {
    isIncome: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    incomeOrExpense() {
      return this.isIncome ? 'הכנסה' : 'הוצאה';
    },
    text() {
      return `צפי ה${this.incomeOrExpense} נמחק מהתזרים`;
    },
  },
  methods: {
    closeSummaryToaster() {
      EventBus.$emit('close-toaster');
    },
  },
};
</script>

<style lang="scss" scoped>
.bold {
  font-weight: bold;
}
</style>
