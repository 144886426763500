<template>
  <div class="prediction-details">
    זה הסכום שהמערכת צופה ש{{actionText}} ב"{{ predictedTransaction.name }}" ב{{ predictionTimePeriod }} החודש
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'PredictionDetails',
  props: {
    predictedTransaction: {
      type: Object,
      required: true,
    },
  },
  computed: {
    predictionTimePeriod() {
      const day = moment(this.predictedTransaction.predictedTransactionDate).date();
      switch (day) {
      case day < 10:
        return 'תחילת';
      case day < 20:
        return 'אמצע';
      default:
        return 'סוף';
      }
    },
    actionText() {
      return this.isIncomeTransaction ? 'יכנס' : 'יצא';
    },
    isIncomeTransaction() {
      return this.predictedTransaction.isIncome;
    },
  },
};
</script>

<style lang="scss" scoped>

  .prediction-details {
    line-height: 16px;
  }

</style>
