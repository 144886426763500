<template>
  <div class="transaction-amount">
    <span>{{ formattedAmount }}</span>
    <span class="nis-currency">₪</span>
  </div>
</template>

<script>
import moneyUtils from '@/utils/money';

export default {
  name: 'TransactionAmount',
  props: {
    amount: {
      type: Number,
      required: true,
    },
  },
  computed: {
    formattedAmount() {
      return moneyUtils.formatAmountWithFraction(this.amount);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '@/scss/category-color-mixins';

  .transaction-amount {
    font-size: 46px;
    line-height: 46px;
    font-weight: bold;
    @include category-color;

    .nis-currency {
      font-size: 32px;
      font-weight: bold;
    }
  }

</style>
