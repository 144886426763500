<template>
  <div class="remove-predicted-wo-actual-popup-flow">
    <component :is="component"
               :predicted-transaction="predictedTransaction"
               :close-action="close"
               :next-action="next"
               :back-action="back"/>
  </div>
</template>

<script>
import Segment from '../../../../../../../../../../../../Segment';
import EditEmptyEnvelope from '../EditEmptyEnvelope.vue';
import DeleteEmptyEnvelope from '../DeleteEmptyEnvelope.vue';
import CancelPredictionSummary from '../CancelPredictionSummary.vue';

export default {
  name: 'EditEmptyEnvelopeFlow',
  components: {
    EditEmptyEnvelope,
    DeleteEmptyEnvelope,
    CancelPredictionSummary,
  },
  props: {
    predictedTransaction: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      component: EditEmptyEnvelope,
    };
  },
  created() {
    this.component = this.startComponent;
  },
  computed: {
    isBalanced() {
      return this.predictedTransaction.balanceDatePassed;
    },
    startComponent() {
      return this.isBalanced ? DeleteEmptyEnvelope : EditEmptyEnvelope;
    },
  },
  methods: {
    close() {
      Segment.trackUserInteraction('EditEmptyEnvelopeFlow_Closed', {
        predictedTransaction: this.predictedTransaction,
      });
      this.$emit('close');
    },
    back() {
      this.component = this.startComponent;
    },
    next() {
      this.component = CancelPredictionSummary;
    },
  },
};
</script>
