<template>
  <div class="remove-predicted-wo-actual">
    <colored-line-popup :class="coloredLinePopupVariant" :close-action="closeAction">

      <template v-slot:top-content>
        <div class="title" :class="transactionCategoryClass">
          <div v-if="isSavingTransaction">
            <span class="name">צפי הפקדה קבועה לחיסכון</span>
          </div>
          <div v-else>
            <span class="name">צפי {{transactionType}} קבועה</span>
            • <span class="category">{{ predictedTransaction.expense }}</span>
          </div>
        </div>
        <transaction-amount :amount="predictedTransaction.predictedAmount" :class="transactionCategoryClass"/>
        <prediction-details :predicted-transaction="predictedTransaction" />
      </template>
      <template v-slot:footer>
        <div class="cancel" v-on:click="onCancelPredictedTransaction">
          <span class="delete-text">מחיקת צפי {{transactionType}}</span>
        </div>
      </template>
    </colored-line-popup>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

import TransactionAmount from '../TransactionAmount.vue';
import PredictionDetails from '../PredictionDetails.vue';
import Segment from '../../../../../../../../../../../Segment';

const INCOME_TEXT = 'הכנסה';
const EXPENSE_TEXT = 'הוצאה';

export default {
  name: 'DeleteEmptyEnvelope',
  components: {
    TransactionAmount,
    PredictionDetails,
    ColoredLinePopup: BaseUI.ColoredLinePopup,
  },
  props: {
    predictedTransaction: {
      type: Object,
      required: true,
    },
    closeAction: {
      type: Function,
      required: true,
    },
    nextAction: {
      type: Function,
      required: true,
    },
  },
  created() {
    Segment.trackUserInteraction('DeleteEmptyEnvelopeShown', {
      predictedTransaction: this.predictedTransaction,
    });
  },
  computed: {
    coloredLinePopupVariant() {
      if (this.isSavingTransaction) {
        return 'savings';
      }
      return this.isIncomeTransaction ? 'success' : 'fixed';
    },
    transactionCategoryClass() {
      if (this.isSavingTransaction) {
        return 'savings';
      }
      return this.isIncomeTransaction ? 'income' : 'fixed';
    },
    isIncomeTransaction() {
      return this.predictedTransaction.isIncome;
    },
    isSavingTransaction() {
      return this.predictedTransaction.isSaving;
    },
    transactionType() {
      return this.isIncomeTransaction ? INCOME_TEXT : EXPENSE_TEXT;
    },
  },
  methods: {
    onCancelPredictedTransaction() {
      Segment.trackUserInteraction('DeleteEmptyEnvelopeCancelPredictionClicked', {
        predictedTransaction: this.predictedTransaction,
      });
      this.nextAction();
    },
  },
};
</script>

<style lang="scss">
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '@/scss/category-color-mixins';

  .remove-predicted-wo-actual {
    text-align: right;

    .title {
      font-weight: bold;
      margin-bottom: 24px;
      line-height: 10px;
      @include category-color;
      .category {
        font-weight: normal;
      }
    }

    .transaction-amount {
      margin-bottom: 12px;
    }

    .cancel {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .delete-text {
        color: $riseup_black;
        font-size: 18px;
        font-weight: normal;
      }
    }
  }

</style>
