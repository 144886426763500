<template>
  <div class="remove-predicted-wo-actual">
    <colored-line-popup :class="coloredLinePopupVariant" :close-action="closeAction">

      <template v-slot:top-content>
        <div class="card-header" :class="transactionCategoryClass">
          <div v-if="isSavingTransaction">
            <span class="name">צפי הפקדה קבועה לחיסכון</span>
          </div>
          <div v-else>
            <span class="name">צפי {{transactionType}} קבועה</span>
            • <span class="category">{{ predictedTransaction.expense }}</span>
          </div>
        </div>
      </template>

      <template v-slot:bottom-content>
        <div class="description">
          <div v-if="isIncomeTransaction">כמה צפוי להיכנס מ'{{ predictedTransaction.name }}' ב{{ monthName }}</div>
          <div v-else>כמה צפוי לצאת על '{{ predictedTransaction.name }}' ב{{ monthName }} {{ adjustedCashflowMonth }}</div>
        </div>
        <amount-input :value="amount" @input="setAmount" :autofocus="true" label=""/>
        <riseup-button class="save-button"
                       :action="saveAmount"
                       :variant="'primary'"
                       :size="'slim'"
                       :title="'עדכון הסכום הצפוי'"
                       :disabled="!amountChanged || amount == null || amount === 0"/>
      </template>

      <template v-slot:footer>
        <div class="cancel" v-on:click="onCancelPredictedTransaction">
          <span class="delete-text">מחיקת צפי {{transactionType}}</span>
        </div>
      </template>
    </colored-line-popup>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import EventBus from '@/event-bus/event-bus';
import dateUtils from '@/utils/dates';

import PredictionUpdatedToaster
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/toasters/PredictionUpdatedToaster';
import Segment from '@/Segment';

const INCOME_TEXT = 'הכנסה';
const EXPENSE_TEXT = 'הוצאה';

export default {
  name: 'EditEmptyEnvelope',
  components: {
    ColoredLinePopup: BaseUI.ColoredLinePopup,
    RiseupButton: BaseUI.RiseupButton,
    AmountInput: BaseUI.AmountInput,
  },
  props: {
    predictedTransaction: {
      type: Object,
      required: true,
    },
    closeAction: {
      type: Function,
      required: true,
    },
    nextAction: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      amount: Math.abs(this.predictedTransaction.predictedAmount),
    };
  },
  created() {
    Segment.trackUserInteraction('EditEmptyEnvelopeShown', {
      predictedTransaction: this.predictedTransaction,
    });
  },
  computed: {
    ...mapState('cashflowView', ['presentedBudgetDate', 'cashflowStartDay']),
    predictedAmount() {
      return Math.abs(this.predictedTransaction.predictedAmount);
    },
    coloredLinePopupVariant() {
      if (this.isSavingTransaction) {
        return 'savings';
      }
      return this.isIncomeTransaction ? 'success' : 'fixed';
    },
    transactionCategoryClass() {
      if (this.isSavingTransaction) {
        return 'savings';
      }
      return this.isIncomeTransaction ? 'income' : 'fixed';
    },
    isIncomeTransaction() {
      return this.predictedTransaction.isIncome;
    },
    isSavingTransaction() {
      return this.predictedTransaction.isSaving;
    },
    transactionType() {
      return this.isIncomeTransaction ? INCOME_TEXT : EXPENSE_TEXT;
    },
    monthName() {
      return moment(this.predictedTransaction.predictedTransactionDate).locale('he').format('MMM');
    },
    amountChanged() {
      return this.amount !== this.predictedAmount;
    },
    adjustedCashflowMonth() {
      if (this.cashflowStartDay === 1) {
        return '';
      }
      const startDate = dateUtils.startOfMonthText(this.presentedBudgetDate, this.cashflowStartDay);
      const endDate = dateUtils.endOfMonthText(this.presentedBudgetDate, this.cashflowStartDay);
      return ` (מ־${startDate} עד ${endDate})`;
    },

  },
  methods: {
    ...mapActions('editCashflow', ['updateEnvelopeAmount']),
    onCancelPredictedTransaction() {
      Segment.trackUserInteraction('EditEmptyEnvelopeCancelPredictionClicked', {
        predictedTransaction: this.predictedTransaction,
      });
      this.nextAction();
    },
    setAmount(amount) {
      this.amount = amount;
    },
    async saveAmount() {
      Segment.trackUserInteraction('EditEmptyEnvelopeSaveClicked', {
        envelopeId: this.predictedTransaction.envelopeId,
        amount: this.amount,
        isIncome: this.isIncomeTransaction,
        predictedAmount: this.predictedAmount,
      });
      await this.updateEnvelopeAmount({
        sequenceId: this.predictedTransaction.sequenceId,
        envelopeId: this.predictedTransaction.envelopeId,
        amount: this.isIncomeTransaction ? -this.amount : this.amount,
      });
      this.closeAction();
      EventBus.$emit('reset-scroll');

      EventBus.$emit('open-toaster', {
        component: PredictionUpdatedToaster,
        props: {
          isIncome: this.isIncomeTransaction,
          isSaving: this.isSavingTransaction,
        },
      });
    },
  },
};
</script>

<style lang="scss">
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '@/scss/category-color-mixins';

  .remove-predicted-wo-actual {
    text-align: right;

    .card-header {
      font-family: SimplerPro;
      font-size: 15px;
      font-weight: bold;
      @include category-color;
      .category {
        font-weight: normal;
      }
    }

    .description {
      font-weight: bold;
      font-size: 19px;
    }

    .save-button {
      width: 100%;
    }

    .cancel {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .delete-text {
        font-weight: normal;
        font-size: 18px;
        color: $riseup_black;
      }
    }
  }

</style>
