<template>
  <move-prediction-to-saving-summary :predicted-transaction="predictedTransaction"
                          :from-category="fixedSavingCategory"
                          :to-category="fixedCategory"
                          @save.stop="onSave"/>
</template>

<script>
import { mapActions } from 'vuex';
import EventBus from '@/event-bus/event-bus';
import TransactionMovedToaster
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/toasters/TransactionMovedToaster';
import CashflowViewConsts from '@/mixins/CashflowViewConsts';
import cashflowViewConsts from '@/constants/cashflow-view';
import SegmentTracker from '@/mixins/SegmentTracker';
import MovePredictionToSavingSummary from './MovePredictionToSavingSummary';

export default {
  name: 'MoveSavingPredictionToPrediction',
  components: {
    MovePredictionToSavingSummary,
  },
  props: {
    predictedTransaction: { type: Object, required: true },
  },
  mixins: [CashflowViewConsts, SegmentTracker],
  created() {
    this.initTracker('MoveSavingPredictionToPrediction_', { predictedTransaction: this.predictedTransaction });
    this.track('Entered');
  },
  methods: {
    ...mapActions('editCashflow', ['changeEnvelopeExpenseCategory']),

    onSave() {
      this.track('SaveClicked');
      this.changeEnvelopeExpenseCategory({
        envelopeId: this.predictedTransaction.envelopeId,
        sequenceId: this.predictedTransaction.sequenceId,
        expenseCategory: cashflowViewConsts.OTHER_CATEGORY,
        isIncome: this.predictedTransaction.isIncome,
      });

      EventBus.$emit('open-toaster', {
        component: TransactionMovedToaster,
        props: {
          changedTransaction: { ...this.predictedTransaction, isSaving: false },
          targetCategory: this.fixedCategory,
        },
      });
    },
  },
};
</script>
