<template>
  <toaster @close-toaster="closeToaster" class="change-expense-category-toaster">
    <template v-slot:text>
      <span><span class="bold">ה{{ amountTypeName }} הקבועה</span> עברה ל<span class="bold">{{ newExpenseCategory }}</span></span>
    </template>
  </toaster>
</template>

<script>
import EventBus from '@/event-bus/event-bus';
import Toaster from '../../../../../../base-components/Toaster';

export default {
  name: 'TransactionMovedToaster',
  components: {
    Toaster,
  },
  props: {
    isIncome: {
      type: Boolean,
      required: true,
    },
    newExpenseCategory: {
      type: String,
      required: true,
    },
  },
  computed: {
    amountTypeName() {
      return this.isIncome ? 'הכנסה' : 'הוצאה';
    },
  },
  methods: {
    closeToaster() {
      EventBus.$emit('close-toaster');
    },
  },
};
</script>

<style lang="scss" scoped>
.change-expense-category-toaster{
  .bold{
    font-weight: bold;
  }
}

</style>
