<template>
  <div class="cancel-predicted-transaction-summary">
    <popup :title="`האם למחוק את ה${transactionType} הקבועה הזו? `"
           :buttons="buttonOptions"
           :back-action="backAction">
      <template v-slot:content>
        <div class="text">המערכת לא תצפה את ה{{transactionType}}
          ב"{{ predictedTransaction.name }}" החודש ובחודשים הבאים.
        </div>
      </template>
      <template v-slot:footer>
        <div class="cancel">
          <div class="delete-text" v-on:click="closeAction">ביטול</div>
        </div>
      </template>
    </popup>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import EventBus from '@/event-bus/event-bus';
import PredictionDeletedToaster
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/toasters/PredictionDeletedToaster';
import Segment from '../../../../../../../../../../../Segment';

const INCOME_TEXT = 'הכנסה';
const EXPENSE_TEXT = 'הוצאה';

export default {
  name: 'CancelPredictionSummary',
  components: {
    Popup: BaseUI.Popup,
  },
  created() {
    Segment.trackUserInteraction('CancelPredictionSummary_Shown', {
      predictedTransaction: this.predictedTransaction,
    });
  },
  props: {
    predictedTransaction: {
      type: Object,
      required: true,
    },
    closeAction: {
      type: Function,
      required: true,
    },
    backAction: {
      type: Function,
      required: true,
    },
  },
  computed: {
    buttonOptions() {
      return [{
        title: 'כן, למחוק',
        action: this.onSave,
        color: BaseUI.LayoutConsts.COLOR_VARIANT.RED,
      }];
    },
    transactionType() {
      return this.isIncomeTransaction ? INCOME_TEXT : EXPENSE_TEXT;
    },
    isIncomeTransaction() {
      return this.predictedTransaction.isIncome;
    },
  },
  methods: {
    ...mapActions('editCashflow', ['cancelEnvelope']),
    async onSave() {
      Segment.trackUserInteraction('CancelPredictionSummary_SaveClicked', {
        predictedTransaction: this.predictedTransaction,
      });
      this.closeAction();
      this.cancelEnvelope({ envelopeId: this.predictedTransaction.envelopeId, sequenceId: this.predictedTransaction.sequenceId });

      EventBus.$emit('open-toaster', {
        component: PredictionDeletedToaster,
        props: {
          isIncome: this.isIncomeTransaction,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '@/scss/category-color-mixins';

  .cancel-predicted-transaction-summary {
    .text {
      white-space: pre-line;
    }

    .cancel {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .delete-text {
        color: $riseup_black;
        font-size: 18px;
        font-weight: normal;
      }
    }
  }

</style>
