<template>
  <div class="edit-predicted-transaction-summary">
    <popup :title="`ה${incomeOrExpense} תעבור`"
           :buttons="buttonOptions"
           :back-action="backAction">
      <template v-slot:content>
        <change-expense-category-path
          :cashflow-category="isIncome ? 'fixed-income' : 'fixed'"
          :category-name="'קבועות'"
          :old-expense-category="oldExpenseCategory"
          :new-expense-category="newExpenseCategory" />
        <transaction-details v-if="hasActual" :transaction="predictedTransaction.actual" />
        <div v-else>
          <transaction-amount :amount="predictedTransaction.predictedAmount" />
          <prediction-details :predicted-transaction="predictedTransaction" />
        </div>
      </template>
    </popup>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import EventBus from '@/event-bus/event-bus';
import ChangeExpenseCategoryToaster
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/toasters/ChangeExpenseCategoryToaster';
import transactionUtils from '../../../../../../../../../../../utils/transaction';
import TransactionDetails from '../TransactionDetails.vue';
import PredictionDetails from '../PredictionDetails.vue';
import ChangeExpenseCategoryPath from '../ChangeExpenseCategoryPath.vue';
import Segment from '../../../../../../../../../../../Segment';
import TransactionAmount from '../TransactionAmount.vue';

export default {
  name: 'EditPredictedTransactionSummary',
  components: {
    TransactionAmount,
    Popup: BaseUI.Popup,
    TransactionDetails,
    PredictionDetails,
    ChangeExpenseCategoryPath,
  },
  created() {
    Segment.trackUserInteraction('EditPredictedTransactionSummary_Shown', {
      predictedTransaction: this.predictedTransaction,
      newExpenseCategory: this.newExpenseCategory,
    });
  },
  props: {
    predictedTransaction: {
      type: Object,
      required: true,
    },
    oldExpenseCategory: {
      type: String,
      requires: true,
    },
    newExpenseCategory: {
      type: String,
      requires: true,
    },
    backAction: {
      type: Function,
      required: true,
    },
    closeAction: {
      type: Function,
      required: true,
    },
  },
  computed: {
    hasActual() {
      return !!this.predictedTransaction.actual;
    },
    buttonOptions() {
      return [{
        title: 'אישור',
        size: 'slim',
        action: this.onSave,
        variant: 'primary',
      }];
    },
    isIncome() {
      return this.predictedTransaction.isIncome;
    },
    incomeOrExpense() {
      return transactionUtils.incomeOrExpense(this.predictedTransaction);
    },
  },
  methods: {
    ...mapActions('editCashflow', ['changeEnvelopeExpenseCategory']),
    async onSave() {
      Segment.trackUserInteraction('EditPredictedTransactionSummary_SaveClicked', {
        predictedTransaction: this.predictedTransaction,
        newExpenseCategory: this.newExpenseCategory,
      });
      this.closeAction();
      if (!this.predictedTransaction.envelopeId || !this.predictedTransaction.sequenceId || !this.newExpenseCategory) {
        // temporary code to catch cases where there's missing data
        const actualTransactionId = this.predictedTransaction.actual.transactionId;
        throw new Error('Error! data is missing in order to call changeEnvelopeExpenseCategory.'
+ `transactionId: ${actualTransactionId} envelopeId: ${this.predictedTransaction.envelopeId} sequenceId: ${this.predictedTransaction.sequenceId}`);
      }
      EventBus.$emit('open-toaster', {
        component: ChangeExpenseCategoryToaster,
        props: {
          isIncome: this.isIncome,
          newExpenseCategory: this.newExpenseCategory,
        },
      });
      await this.changeEnvelopeExpenseCategory({
        envelopeId: this.predictedTransaction.envelopeId,
        sequenceId: this.predictedTransaction.sequenceId,
        expenseCategory: this.newExpenseCategory,
        isIncome: this.isIncome,
      });
    },
  },
};
</script>

<style lang="scss">
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../../../../../../../../../scss/category-color-mixins';

  .edit-predicted-transaction-summary {

    .change-category-path {
      margin-bottom: 40px;
    }

    .transaction-details {
      margin-bottom: 24px;
    }

    .explanation {
      font-weight: bold;
      line-height: 16px;

      @include category-color;
    }
  }

</style>
