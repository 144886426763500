<template>
  <div class="change-category-path">
    <div class="category-text-wrapper">
      <div class="category-text" :class="cashflowCategory">
        מ{{ categoryName }}
        <div class="expense-name">{{ oldExpenseCategory }}</div>
      </div>
      <div class="category-text left" :class="cashflowCategory">
        ל{{ categoryName }}
        <div class="expense-name">{{ newExpenseCategory }}</div>
      </div>
    </div>
    <div class="category-path">
      <div class="circle" :class="cashflowCategory"></div>
      <div class="gradient-line" :class="[`right-${cashflowCategory}`, `left-${cashflowCategory}`]"></div>
      <div class="left-arrow fa fa-caret-left" :class="cashflowCategory"></div>
      <div class="circle" :class="cashflowCategory"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ChangeExpenseCategoryPath',
  props: {
    cashflowCategory: {
      type: String,
      required: true,
    },
    categoryName: {
      type: String,
      required: true,
    },
    oldExpenseCategory: {
      type: String,
      required: true,
    },
    newExpenseCategory: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../../../../../../../../scss/category-color-mixins';

  .change-category-path {
    direction: rtl;

    .category-text-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 4px;

      .category-text {
        font-size: 16px;
        line-height: 16px;
        font-family: SimplerPro;
        font-weight: bold;

        @include category-color;

        &.left {
          text-align: left;
        }

        .expense-name {
          margin-top: 2px;
          font-weight: normal;
        }
      }
    }

    .category-path {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .gradient-line {
        height: 1px;
        width: 85%;

        &.right-variable {
          &.left-variable {
            background: linear-gradient(to left, $riseup_yellow, $riseup_yellow),
          }

          &.left-fixed {
            background: linear-gradient(to left, $riseup_yellow, $riseup_red),
          }
        }

        &.right-fixed {
          &.left-variable {
            background: linear-gradient(to left, $riseup_red, $riseup_yellow),
          }

          &.left-fixed {
            background: linear-gradient(to left, $riseup_red, $riseup_red),
          }
        }

        &.right-fixed-income {
          &.left-fixed-income {
            background: linear-gradient(to left, $riseup_green, $riseup_green),
          }
        }

      }

      .left-arrow {
        color: $riseup_yellow;
        transform: scale(1.5,1);
        margin-left: 1px;

        @include category-color;
      }

      .circle {
        width: 16px;
        height: 16px;
        border-radius: 50%;

        @include category-background-color;
      }
    }
  }

</style>
